// only button with type wysiwyg.viewer.components.SiteButton component type
// allows to override GFPP buttons

import componentJson from '../.component.json';

export const buttonStructure = {
  componentType: 'wysiwyg.viewer.components.SiteButton',
  style: {
    type: 'ComponentStyle',
    styleType: 'custom',
    style: {
      properties: {
        'alpha-bg': '1',
        'alpha-bgh': '1',
        'alpha-brd': '1',
        'alpha-brdh': '0',
        'alpha-txt': '1',
        'alpha-txth': '1',
        bg: 'color_18',
        bgh: 'color_17',
        'boxShadowToggleOn-shd': 'false',
        brd: 'color_14',
        brdh: 'color_12',
        brw: '0',
        fnt: 'font_8',
        rd: '0px',
        shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
        txt: 'color_8',
        txth: 'color_11',
      },
      propertiesSource: {
        'alpha-bg': 'value',
        'alpha-bgh': 'value',
        'alpha-brd': 'value',
        'alpha-brdh': 'value',
        'alpha-txt': 'value',
        'alpha-txth': 'value',
        bg: 'theme',
        bgh: 'theme',
        'boxShadowToggleOn-shd': 'value',
        brd: 'theme',
        brdh: 'theme',
        brw: 'value',
        fnt: 'theme',
        rd: 'value',
        shd: 'value',
        txt: 'theme',
        txth: 'theme',
      },
    },
    componentClassName: 'wysiwyg.viewer.components.SiteButton',
    skin: 'wysiwyg.viewer.skins.button.BasicButton',
  },
  layout: {
    x: 16,
    y: 100,
    width: 140,
    height: 40,
  },
  type: 'Component',
  data: {
    label: 'Play the video',
    type: 'LinkableButton',
    name: 'superPuperButton',
    id: 'button-buy',
  },
  props: {
    align: 'center',
    margin: 0,
    type: 'ButtonProperties',
  },
  connections: {
    type: 'ConnectionList',
    items: [
      {
        type: 'ConnectionItem',
        role: 'button',
        isPrimary: true,
        controllerId: componentJson.id,
      },
    ],
  },
};
