import { FlowEditorSDK, ComponentRef } from '@wix/yoshi-flow-editor';

const TOKEN = 'token';

export const panelUrlBuilder = (
  editorSDK: FlowEditorSDK,
  componentRef: ComponentRef,
  panelName: string,
) => {
  const inWatchMode = process.env.NODE_ENV !== 'production';
  // During yoshi-flow-editor start we want have local rendered settings panel. For prod - we are using static html file.
  const baseUrl = inWatchMode
    ? `https://localhost:3200/settings/${panelName}.html`
    : `./settings/${panelName}.html`;

  return `${baseUrl}?componentId=${componentRef.id}`;
};

export function openSettingsPanel(
  editorSDK: FlowEditorSDK,
  panelName: string,
  { componentRef }: { componentRef: ComponentRef },
) {
  editorSDK.editor.openComponentPanel(TOKEN, {
    title: 'Widget Settings',
    url: panelUrlBuilder(editorSDK, componentRef, panelName),
    height: 240,
    componentRef,
  });
}

export async function addComponent(
  editorSDK: FlowEditorSDK,
  componentDefinition: any,
) {
  const pageRef = await editorSDK.pages.getCurrent(TOKEN);

  return editorSDK.document.components.add(TOKEN, {
    componentDefinition,
    pageRef,
  });
}
