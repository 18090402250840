import componentJson from '../.component.json';

export const textStructure = {
  type: 'Component',
  skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
  layout: {
    width: 400,
    height: 40,
    x: 16,
    y: 22,
  },
  componentType: 'wysiwyg.viewer.components.WRichText',
  data: {
    type: 'StyledText',
    text: '<p class="font_7">Click to play the video</p>',
  },
  style: 'txtNew',
  connections: {
    type: 'ConnectionList',
    items: [
      {
        type: 'ConnectionItem',
        role: 'text',
        isPrimary: true,
        controllerId: componentJson.id,
      },
    ],
  },
};
