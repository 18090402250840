import componentJson from '../.component.json';
import { textStructure } from './text';
import { buttonStructure } from './button';

export const getWidgetStructure = (applicationId: string) => ({
  componentType: 'platform.components.AppWidget',
  layout: {
    x: 20,
    y: 20,
    width: 440,
    height: 180,
  },
  // AppWidget should have one root element,
  // it can be container or simple element like a button
  components: [
    {
      componentType: 'mobile.core.components.Container',
      components: [
        // look the file structure for more details
        textStructure,
        buttonStructure,
      ],
      style: {
        componentClassName: 'mobile.core.components.Container',
        skin: 'wysiwyg.viewer.skins.area.RectangleArea',
        style: {
          properties: { 'alpha-bg': '1', bg: 'color_22' },
          propertiesSource: { 'alpha-bg': 'value', bg: 'theme' },
        },
        styleType: 'custom',
        type: 'ComponentStyle',
      },
    },
  ],
  data: {
    controllerType: componentJson.id,
    applicationId,
    id: componentJson.id,
  },
});
