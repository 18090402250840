import { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { addComponent } from '../../utils/editor';
import { getWidgetStructure } from './elements/widget';
import componentJson from './.component.json';

const TOKEN = 'token';

export async function install(editorSDK: FlowEditorSDK) {
  const applicationId = await editorSDK.info.getAppDefinitionId(TOKEN);

  // when application first time adding, we have to add all needed elements to stage

  // app widget is a container for the rest of elements
  // look inside widgetStructure for more details
  await addComponent(editorSDK, getWidgetStructure(applicationId));
}

export default {
  type: componentJson.id,
};
