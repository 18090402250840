import {
  EcomComponent,
  withEcomPlatform,
} from '@wix/ecom-platform-sdk/dist/es/src';
import { withMembersArea } from '@wix/members-area-integration-kit';

const ecomComponents = [
  EcomComponent.CART_ICON,
  EcomComponent.CART,
  EcomComponent.CHECKOUT,
  EcomComponent.THANK_YOU_PAGE,
];
export default () => {
  return withMembersArea(
    withEcomPlatform(
      {
        editorReady: async () => ({}),
        handleAction: async () => ({}),
      },
      ecomComponents,
    ),
    {
      installAutomatically: false,
    },
  );
};
